// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

// Bootstrap
import 'jquery'
import 'popper.js'
import 'bootstrap'
import '../stylesheets/application.scss'

// import 'bootstrap/dist/js/bootstrap'
// import 'jquery/dist/jquery'
// import 'popper.js/dist/esm/popper'

// import 'bootstrap/dist/js/bootstrap'
// import 'bootstrap/dist/css/bootstrap'
// require('stylesheets/application.scss')

Rails.start()
ActiveStorage.start()

// This will activate the tooltips on any DOM element that has 'data-toggle'='tooltip'
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// $('.collapse').on('show.bs.collapse', function () {
//   $('.collapse').not($(this)).collapse('hide');
//  });
